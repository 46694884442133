
.black-field {
  background-color: black;
  color: white;
  border: 0.0625rem solid #4a4b4c;
  height: 2.5rem;
  width: 60%;
  padding: 0.5rem;
  border-radius: 0.125rem
}

.black-field-projects {
  background-color: black;
  color: white;
  border: 0.0625rem solid #4a4b4c;
  height: 2.5rem;
  width: 100%;
  padding: 0.5rem;
  border-radius: 0.125rem
}

.black-field-projects-details {
  background-color: black;
  color: white;
  border: 0.0625rem solid #4a4b4c;
  height: 2.5rem;
  width: 50%;
  padding: 0.5rem;
  padding-right: 1rem;
  border-radius: 0.125rem
}

.black-button-field {
  background-color: black;
  color: white;
  border: 0.0625rem solid #4a4b4c;
  height: 2.5rem;
  width: 60%;
  padding: 0.15rem;
  border-radius: 0.125rem
}

.black-button-field-projects {
  background-color: black;
  color: white;
  border: 0.0625rem solid #4a4b4c;
  height: 2.5rem;
  width: 100%;
  padding: 0.15rem;
  border-radius: 0.125rem
}
.grey-button-projects {
  background-color: #333435;
  color: white;
  width: 50%;
  height: 2rem;
  border: none;
  border-radius: 0.125rem;
  padding: 0.1rem
}

.black-button-projects {
  background-color: black;
  color: white;
  width: 50%;
  height: 2rem;
  border: none;
  border-radius: 0.125rem;
  padding: 0.1rem
}

.grey-button {
  background-color: #333435;
  color: white;
  width: 50%;
  height: 2rem;
  border: none;
  border-radius: 0.125rem;
  padding: 0.1rem
}

.black-button {
  background-color: black;
  color: white;
  width: 50%;
  height: 2rem;
  border: none;
  border-radius: 0.125rem;
  padding: 0.1rem
}

.black-select {
  background-color: black;
  color: white;
  border: 0.0625rem solid #4a4b4c;
  height: 2.5rem;
  width: 100%;
  padding: 0.5rem;
  border-radius: 0.125rem;
  -webkit-appearance: "none";
  appearance: 'none';
  background-image: url(./components/UserManager/DetailsComponents/Dropdown.svg);
}

.black-select-projects {
  background-color: black;
  color: white;
  border: 0.0625rem solid #4a4b4c;
  height: 2.5rem;
  width: 95%;
  padding: 0.5rem;
  border-radius: 0.125rem;
  -webkit-appearance: "none";
  appearance: 'none';
  background-image: url(./components/UserManager/DetailsComponents/Dropdown.svg),
}

.black-select-arrow {
  -webkit-appearance: "none";
  appearance: 'none';
  background-image: url(./components/UserManager/DetailsComponents/Dropdown.svg),
}